<template>
    <!-- Footer Start -->
    <footer class="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
        <div class="container">

            <div class="py-[60px] px-0">
                <div class="flex justify-center flex-wrap ">
                    <div class="lg:w-1/2">
                        <a class="text-[22px] focus:outline-none">
                            <img class="w-2/5" src="/assets/images/logo-light.png" alt="">
                        </a>
                        <p class="mt-6 text-gray-300">Buy gift cards for anything under the sun.
                            No account necessary.</p>
                    </div>
                    <!--end col-->

                    <div class="">
                        <ul class="flex mt-6">
                            <li><a href=""
                                    class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"> Home</a></li>
                            <li class="mx-2"><a href="/explore"
                                    class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"> Explore</a></li>

                            <li class="mx-2"><a href="#"
                                    class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"> About us</a>
                            </li>
                            <li class="mx-2"><a href="#"
                                    class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"> Services</a>
                            </li>

                            <li class="mx-2"><a href="#"
                                    class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"> Login</a></li>
                        </ul>
                    </div>
                    <!--end col-->
                </div>
                <!--end grid-->
            </div>
            <!--end col-->

        </div>
        <!--end container-->

        <div class="py-[30px] px-0 border-t border-slate-800">
            <div class="container text-center">
                <div class="grid md:grid-cols-2 items-center">
                    <div class="md:text-left text-center">
                        <p class="mb-0">©Coreavenue</p>
                    </div>
                </div>
                <!--end grid-->
            </div>
            <!--end container-->
        </div>
    </footer>
    <!--end footer-->
    <!-- Footer End -->

    <!-- Start Cookie Popup -->
    <!-- <div class="cookie-popup fixed max-w-lg bottom-3 right-3 left-3 sm:left-0 sm:right-0 mx-auto bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md py-5 px-6 z-10">
           <p class="text-slate-400">This website uses cookies to provide you with a great user experience. By using it, you accept our <a href="https://shreethemes.in" target="_blank" class="text-emerald-600 dark:text-emerald-500 font-semibold">use of cookies</a></p>
           <div class="cookie-popup-actions text-right">
               <button class="absolute border-none bg-none p-0 cursor-pointer font-semibold top-2 right-2"><i class="uil uil-times text-dark dark:text-slate-200 text-2xl"></i></button>
           </div>
       </div> -->
    <!--Note: Cookies Js including in plugins.init.js (path like; /assets/js/plugins.init.js) and Cookies css including in _helper.scss (path like; scss/_helper.scss)-->
    <!-- End Cookie Popup -->

    <!-- Back to top -->
    <a href="#" onclick="topFunction()" id="back-to-top"
        class="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 right-5 h-9 w-9 text-center bg-indigo-600 text-white leading-9"><i
            class="uil uil-arrow-up"></i></a>
    <!-- Back to top -->



</template>

<script>
export default {

}
</script>

<style>

</style>