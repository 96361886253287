<template>
  <HeaderComponent></HeaderComponent>
  <router-view :catalogsData="data"></router-view>
  <FooterComponent></FooterComponent>
</template>

<script>
import HeaderComponent from '@/components/headerComponent.vue';
import FooterComponent from '@/components/footerComponent.vue';


export default {
  components: { HeaderComponent, FooterComponent },
  data() {
    return {
      data:
      {
        info: {},
        loop: 0
      },
      page: 1,
      perPage: 12
    }
  },

  methods: {


    loadCatalog: function () {

      this.axios.get('/vendors/v2/catalogs/', { headers: { 'Authorization': 'Bearer ' + this.$cookies.get("isSession") }, })
        .then((e) => {

          var loop = Math.ceil(e.data.count / this.perPage);
          var data = e.data.results

          var start = 0;
          var end = this.perPage

          if (this.page > 1) {
            end = this.page * this.perPage
            start = ((this.page * this.perPage) - this.perPage)
          }

          var sorted = Object.keys(data).slice(start, end).reduce((result, key) => {
            result[key] = data[key];
            return result;
          }, {});

          this.data = {
            info: sorted,
            loop: loop,
            page: this.page
          }
        }).catch((e) => {
          console.log(e);
        });


    }
  },

  created() {
    if (this.$route.query.pg != undefined && typeof parseInt(this.$route.query.pg) === 'number') {
      this.page = this.$route.query.pg
    }
  },

  mounted() {

    if (this.$cookies.isKey('isSession') == false) {

      this.axios.post('/vendors/v2/auth/token/', { client_id: process.env.VUE_APP_EZ_CLIENT_ID, secret_key: process.env.VUE_APP_SECRET_KEY })
        .then((e) => {
          this.$cookies.set("isSession", e.data.access, e.data.expire);
          this.loadCatalog();
          console.log(e);
        }).catch((e) => {
          console.log(e);
        });
    } else {
      if (Object.keys(this.data.info) < 1) {
        this.loadCatalog();
      }
    }
  },


  update() {
    this.loadCatalog();
  }
}

</script>
  

<style>

</style>
