<template>

    <!-- Start Hero -->
    <section class="relative table w-full md:pb-24 pb-16 mt-28">
        <div class="container ">

            <div class=" m-auto columns-1 max-w-2xl content-center " v-if="Object.keys(content).length  > 0">
                <!-- lg:w-[32rem] sm:w-full -->
                <div
                    class="p-6 w-full bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">

                    <div class="flex flex-col justify-between h-full">
                        <div class="flex flex-col">
                            <a href="#">
                                <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                    Order
                                    details</h5>
                            </a>
                            <hr>

                            <div v-for="(cart, index) in content.data" :key="index">
                                <div class="mt-5 mb-2 flex justify-between">
                                    <div class="flex flex-col justify-center">
                                        <div class="flex">
                                            <div
                                                class="mb-2 w-10 h-10 flex flex-col justify-center border border-gray-500 ">
                                                <img class="w-full" :src="cart.image">
                                            </div>
                                            <div class="flex flex-col ml-2">
                                                <span v-text="cart.title"></span>
                                                <span class="text-sm"
                                                    v-text="content.currency.symbol + cart.price"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- v-text="cart.estimate + payMethod" -->
                                  
                                </div>
                                <hr>
                            </div>


                            <div class="mt-5 mb-2 flex justify-between">
                                <div class="flex flex-col justify-center">
                                    <div class="flex">
                                        <div class="flex flex-col ml-2">
                                            <h6 class="font-semibold">TOTAL</h6>
                                            <span class="text-sm">{{ content.currency.symbol+ totalCart }}</span>
                                        </div>
                                    </div>
                                </div>
                                <h6>{{ estimate + payMethod}}</h6>
                            </div>

                            <div class="mt-5">
                                <label>Email address for order</label>
                                <input id="amountBox" type="tel"
                                    class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    required>
                            </div>

                            <div class="mt-5 grid grid-cols-1">
                                <label>Payment method</label>
                                <select @change="estimateFn()" v-model="payMethod" id="countries" placeholder="asA"
                                    class="w-1/2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option v-for="(c,i) in currencies" :key="i" :value="c">{{ c.toUpperCase() }}
                                    </option>

                                </select>
                                <!-- <h3 class="text-2xl uppercase">{{ payMethod}}</h3> -->
                            </div>

                            <div class="flex justify-end">
                                <button @click="purchase()"
                                    class="text-white bg-indigo-600 border border-transparent focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center hover:bg-indigo-700 mr-2 mb-2">
                                    Purchase
                                </button>
                            </div>
                        </div>


                    </div>
                </div>

            </div>


            <div class="m-auto columns-1 max-w-2xl content-center" v-else>
                <div
                    class="p-6 max-w-lg bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">

                    <div class="w-full">

                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-shopping-cart mb-2 w-10 h-10">
                            <circle cx="9" cy="21" r="1"></circle>
                            <circle cx="20" cy="21" r="1"></circle>
                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                        </svg>


                        <h2 class="mb-3 text-3xl font-normal text-gray-800 dark:text-gray-600">Your cart is empty</h2>
                    </div>

                </div>
            </div>


        </div>
    </section>

</template>

<script>
export default {

    data() {
        return {
            content: {},
            currencies: {},
            payMethod: "BTC",
            totalCart: 0,
            estimate: 0,
        }
    },

    methods: {
        purchase: function () {
            var data =
            {
                "price_amount": this.totalCart,
                "price_currency": this.content.currency.code,
                "order_description": "Gift Card Purchase",

            }
            this.axios.post('https://api.nowpayments.io/v1/invoice', data, { headers: { 'x-api-key': process.env.VUE_APP_NOWPAYMENT } })
                .then((e) => {
                    location.href = e.data.invoice_url;
                }).catch((e) => {
                    console.log(e);
                });
        },

        estimateFn: function () {
            this.axios.get('https://api.nowpayments.io/v1/estimate?amount=' + parseFloat(this.totalCart) + '&currency_from=' + this.content.currency.code + '&currency_to=' + this.payMethod + '', { headers: { 'x-api-key': process.env.VUE_APP_NOWPAYMENT } })
                .then((e) => {
                    this.estimate = e.data.estimated_amount
                }).catch((e) => {
                    console.log(e);
                });
        }
    },


    created() {
        if (this.$cookies.isKey("coreCart") != false) {
            this.content = JSON.parse(JSON.stringify(this.$cookies.get("coreCart")));
            this.totalCart = this.content.data.map((e) => { return e.price }).reduce((o, n) => { return parseInt(o) + parseInt(n) });

            this.estimateFn()
        }
    },

    mounted() {
        this.axios.get('https://api.nowpayments.io/v1/currencies', { headers: { 'x-api-key': process.env.VUE_APP_NOWPAYMENT } })
            .then((e) => {
                this.currencies = e.data.currencies
            }).catch((e) => {
                console.log(e);
            });
    }





}
</script>

<style>

</style>