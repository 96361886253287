<template>
    <!-- Start Section-->
    <section class="relative md:py-24 py-16">
        <div class="container">



            <h3 class="text-blue-900 mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Cards we love </h3>


            <div class="text-center" v-if="Object.keys(catalogs).length < 1">
                <spinner-component-vue @loadData="tryagain()" :loaded="load"></spinner-component-vue>
            </div>



            <div class="grid  grid-cols-2 lg:grid-cols-4 gap-4 " v-else>


                <!-- Start Bid -->
                <div class="cursor-pointer  w-100 picture-item" data-groups='["branding"]'
                    v-for="(catalog, index) in catalogs" :key="index">
                    <div
                        class="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out">
                        <div class="relative">
                            <img @click="buyCard(index)" class="w-full" :src="catalog.image" alt="">
                            <div
                                class="absolute right-0 top-0 mt-6 mr-6 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                                <a href="#!"
                                    class="btn btn-icon text-lg bg-white dark:bg-slate-900 border-0 shadow dark:shadow-gray-800 rounded-full text-red-600/20 hover:text-red-600 focus:text-red-600"><i
                                        class="mdi mdi-heart"></i></a>
                            </div>

                            <div
                                class="absolute top-1/2 -translate-y-1/2 right-0 left-0 text-center opacity-0 group-hover:opacity-100 scale-0 group-hover:scale-100 duration-500 ease-in-out">
                                <a
                                    class="btn btn-sm bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">View</a>
                            </div>

                        </div>

                        <div class="p-6 relative">
                            <a class="text-lg font-semibold hover:text-indigo-600 duration-500 ease-in-out"
                                v-text="catalog.title"></a>
                        </div>
                    </div>
                </div>
                <!-- End Bid -->

            </div>
            <!--end grid-->



            <!--end grid-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
</template>

<script>

import spinnerComponentVue from '@/components/spinnerComponent.vue';
export default {
    props: {
        catalogsData: Object,
    },

    components: { spinnerComponentVue },


    data() {
        return {
            catalogs: [],
            load: 'load'
        }
    },

    methods: {
        buyCard: function (e) {
            const card = this.catalogs[e].sku;
            const page = this.catalogsData.page
            this.$router.push({ name: 'buy', query: { sk: card, pg: page } });
        },

        tryagain: function () {
            this.load = 'load'
            this.loadCatalogs();
        },
    },


    updated() {

        if (this.catalogsData.info != undefined && Object.keys(this.catalogsData.info).length < 1) {
            this.load = 'false'
        } else {
            this.load = 'true'
            this.catalogs = this.catalogsData.info
        }
    },


    mounted() {
        if (this.catalogsData.info != undefined && Object.keys(this.catalogsData.info).length > 0) {
            this.catalogs = this.catalogsData.info;
            return;
        }
        this.load = 'load'
    }

}
</script>

<style>

</style>