<template>




    <!-- Start Hero -->
    <section class="w-full relative ">

        <div
            class="w-full relative items-center overflow-hidden md:py-16  py-32 bg-gradient-to-br to-orange-600/20 via-fuchsia-600/20 from-blue-500/20">
            <div class="container">
                <div class="grid grid-cols-1 items-center mt-10">
                    <h4
                        class="text-center font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">
                        Discover Exclusive <br> Digital collectibles</h4>
                    <div class="flex justify-center">
                        <p class=" text-lg max-w-xl text-center ">
                            Buy gift cards for anything under the sun.
                            <br>
                            No account necessary.
                        </p>
                    </div>

                    <div class="mt-8 flex justify-center">
                        <a href="/explore"
                            class="btn bg-blue-500 hover:bg-indigo-700 border-blue-500 hover:border-indigo-700 text-white rounded-md">Explore
                            Products</a>
                    </div>
                </div>
                <!--end grid-->
            </div>
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- End Hero -->

    <!-- Start -->
    <section class="relative md:py-8 py-16">

        <div class="container">

            <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">
                <div class="text-center md:px-6">
                    <div
                        class="w-28 h-28 bg-blue-500/5 text-blue-500 rounded-3xl text-4xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                        <i class="uil uil-airplay"></i>
                    </div>

                    <div class="content mt-7">
                        <a  class="title h5 text-lg font-medium hover:text-blue-500">
                            Enter the amount
                        </a>

                    </div>
                </div>

                <div class="text-center md:px-6">
                    <div
                        class="w-28 h-28 bg-red-600/5 text-red-600 rounded-3xl text-4xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                        <i class="uil uil-card-atm"></i>
                    </div>

                    <div class="content mt-7">
                        <a class="title h5 text-lg font-medium hover:text-red-600">Pay with crypto</a>

                    </div>
                </div>

                <div class="text-center md:px-6">
                    <div
                        class="w-28 h-28 bg-emerald-600/5 text-emerald-600 rounded-3xl text-4xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                        <i class="uil uil-mobile-android"></i>
                    </div>

                    <div class="content mt-7">
                        <a class="title h5 text-lg font-medium hover:text-emerald-600">
                            Redeem your code
                        </a>

                    </div>
                </div>

            </div>
        </div>
        <!--end container-->


    </section>
    <!--end-->





    <!-- Start CTA -->
    <section class="relative md:py-24 py-16 bg-gradient-to-br to-orange-600/30 via-fuchsia-600/30 from-blue-500/30">
        <div class="container">

            <div class="grid grid-cols-1 pb-5 text-center">
                <h3 class="mb-2 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Get a glimps
                </h3>
            </div>

            <div class="text-center" v-if="Object.keys(sorted).length  < 1">
                <spinnerComponentVue :loaded="load"></spinnerComponentVue>
            </div>

            <div v-else>


                <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-5 gap-[30px]">
                    <!-- Start Collection -->
                    <div class="group relative overflow-hidden bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800"
                        v-for="(sort, index) in sorted" :key="index">

                        <div class="flex  h-full">
                            <img :src="sort.image" class="rounded-md" alt="">
                        </div>

                    </div>
                    <!-- End Collection -->
                </div>

                <div class="mt-8 w-full flex justify-center">
                    <a href="/explore"
                        class="btn bg-blue-500 hover:bg-indigo-700 border-blue-500 hover:border-indigo-700 text-white rounded-md">Explore
                        Products</a>
                </div>
            </div>

        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- End CTA -->



</template>

<script>

import spinnerComponentVue from '@/components/spinnerComponent.vue';


export default {

    components: { spinnerComponentVue },

    props: {
        catalogsData: Object,
    },

    data() {
        return {
            sorted: {},
            load: 'load'
        }
    },

    methods: {
        grab: function () {
            if (this.catalogsData.info != undefined && Object.keys(this.catalogsData.info).length > 0) {
                var data = JSON.parse(JSON.stringify(this.catalogsData.info));
                this.sorted = Object.keys(data).slice(Object.keys(data).length - 3, Object.keys(data).length).reduce((result, key) => {
                    result[key] = data[key];
                    return result;
                }, {});
            }
        }
    },

    updated() {
        this.grab();
    },

    mounted() {
        this.load = 'load'
        this.grab();
    }
}
</script>


<style>

</style>
