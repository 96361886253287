<template>


    <div class="w-full relative items-center overflow-hidden bg-gray-200">
        <div class="container">

            <div class="flex w-full md:justify-between justify-center flex-wrap mb-2 lg:mb-">
                <div class="py-2 ">
                    <label for="simple-search" class="sr-only">Search</label>
                    <div class="relative w-full">
                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </div>
                        <input type="text" id="simple-search"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search" required>
                    </div>




                </div>


                <div class="flex h-auto flex-col justify-center">
                    <ul class="list-none ">

                        <li class="inline"><a href="http://linkedin.com/company/shreethemes" target="_blank"
                                class="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-blue-500 dark:hover:border-blue-500 hover:bg-blue-500 dark:hover:bg-blue-500"><i
                                    class="uil uil-linkedin" title="Linkedin"></i></a></li>
                        <li class="inline"><a href="https://www.facebook.com/shreethemes" target="_blank"
                                class="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-blue-500 dark:hover:border-blue-500 hover:bg-blue-500 dark:hover:bg-blue-500"><i
                                    class="uil uil-facebook-f align-middle" title="facebook"></i></a></li>
                        <li class="inline"><a href="https://www.instagram.com/shreethemes/" target="_blank"
                                class="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-blue-500 dark:hover:border-blue-500 hover:bg-blue-500 dark:hover:bg-blue-500"><i
                                    class="uil uil-instagram align-middle" title="instagram"></i></a></li>
                        <li class="inline"><a href="https://twitter.com/shreethemes" target="_blank"
                                class="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-blue-500 dark:hover:border-blue-500 hover:bg-blue-500 dark:hover:bg-blue-500"><i
                                    class="uil uil-twitter align-middle" title="twitter"></i></a></li>
                    </ul>
                </div>
            </div>


        </div>
    </div>

    <!-- Start Navbar -->
    <nav id="topnav" class="defaultscroll is-sticky bg-white dark:bg-slate-900">
        <div class="container">
            <!-- Logo container-->
            <a href="/" class="logo pl-0">
                <img src="/assets/images/logo-dark.png" class="w-36 inline-block dark:hidden" alt="">
                <img src="/assets/images/logo-light.png" class="hidden dark:inline-block" alt="">
            </a>

            <!-- End Logo container-->
            <div class="menu-extras">
                <div class="menu-item">
                    <!-- Mobile menu toggle-->
                    <a class="navbar-toggle" id="isToggle" @click="toggleMenu()">
                        <div class="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a>
                    <!-- End mobile menu toggle-->
                </div>
            </div>

            <!--Login button Start-->
            <ul class="hidden md:block buy-button list-none mb-0">




                <li class=" cursor-pointer inline mb-0">
                    <a
                        class="text-white bg-blue-500 border border-transparent focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center hover:bg-blue-600 mr-2 mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                            class="bi bi-box-arrow-in-right mr-2 -ml-1 w-7 h-4" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                            <path fill-rule="evenodd"
                                d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                        </svg>
                        Login
                    </a>
                </li>

                <li class="cursor-pointer inline mb-0">
                    <a
                        class="text-white bg-blue-800 border border-transparent focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center hover:bg-blue-900 mr-2 mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-columns-gap mr-2 -ml-1 w-7 h-4" viewBox="0 0 16 16">
                            <path
                                d="M6 1v3H1V1h5zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm14 12v3h-5v-3h5zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5zM6 8v7H1V8h5zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H1zm14-6v7h-5V1h5zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1h-5z" />
                        </svg>Create account
                    </a>
                </li>






                <li class="cursor-pointer inline pl-1 mb-0">

                    <a type="button" href="/checkout"
                        class="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-shopping-cart mr-2 -ml-1 w-7 h-4">
                            <circle cx="9" cy="21" r="1"></circle>
                            <circle cx="20" cy="21" r="1"></circle>
                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                        </svg>
                        Cart
                    </a>
                </li>
            </ul>




            <ul class="block md:hidden buy-button list-none mb-0">
                <li class="inline mb-0">
                    <a href=""
                        class="btn btn-icon rounded-full bg-blue-500/5 hover:bg-blue-500 border-blue-500/10 hover:border-blue-500 text-blue-500 hover:text-white"><svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-settings h-4 w-4">
                            <circle cx="12" cy="12" r="3"></circle>
                            <path
                                d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
                            </path>
                        </svg></a>
                </li>

                <li class="inline pl-1 mb-0">
                    <a href="https://1.envato.market/coreavenue" target="_blank"
                        class="btn btn-icon rounded-full bg-blue-500 hover:bg-blue-600 border-blue-500 hover:border-blue-600 text-white"><svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-shopping-cart h-4 w-4">
                            <circle cx="9" cy="21" r="1"></circle>
                            <circle cx="20" cy="21" r="1"></circle>
                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                        </svg></a>
                </li>
            </ul>
            <!--Login button End-->
            <div id="navigation">
                <!-- Navigation Menu-->
                <!-- Navigation Menu-->
                <ul class="lg:hidden navigation-menu  ">
                    <li class=""><a href="index.html" class=" sub-menu-item">Home</a></li>
                    <li><a href="contact-one.html" class="sub-menu-item">Contact</a></li>
                </ul>
                <!--end navigation menu-->
                <!--end navigation menu-->
            </div>
            <!--end navigation-->
        </div>
        <!--end container-->
    </nav>
    <!--end header-->
    <!-- End Navbar -->










</template>

<script>
export default {
    methods: {
        toggleMenu: function () {
            document.getElementById('isToggle').classList.toggle('open');
            var isOpen = document.getElementById('navigation')
            if (isOpen.style.display === "block") {
                isOpen.style.display = "none";
            } else {
                isOpen.style.display = "block";
            }
        }
    },
    mounted() {
        // console.log("sdsduniu")
    },
}
</script>

<style>

</style>