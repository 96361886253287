
import { createRouter, createWebHistory } from "vue-router";
import buy from './pages/buy-page.vue'
import explore from './pages/explore-page.vue'
import landing from './pages/landing-page.vue'
import checkout from './pages/checkout-page.vue'


// var path = window.location.pathname;
// var page = path.split("/").pop();
// var paths = page;


// // if (path.split("/")[1] !== 'private') {
// //     page = "coming"
// //     paths = ""
// // }

const routes = [
    { name: "Coreavenue", path: '/', component: landing },
    { name: "explore", path: '/explore', props: true, component: explore },
    { name: "buy", path: '/buy', props: true, component: buy },
    { name: "checkout", path: '/checkout', props: true, component: checkout },
    { name: "Coreavenue", path: '/:catchAll(.*)', component: landing }
]

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

export default router;

