<template>


    <!-- Start Hero -->
    <section class="relative table w-full md:pb-24 pb-16 mt-28">
        <div class="container">



            <div class="text-center" v-if="Object.keys(card).length  < 1">
                <spinnerComponentVue @loadData="again()" :loaded="load"></spinnerComponentVue>
            </div>



            <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]" v-else>
                <div class="lg:col-span-6 md:col-span-5">
                    <div class="sticky top-20">
                        <img :src="card.image" class="w-full rounded-md shadow-md dark:shadow-gray-800" alt="">
                    </div>
                </div>
                <!--end col-->

                <div class="lg:col-span-6 md:col-span-7">
                    <div class="">
                        <h5 class="lg:text-4xl lg:leading-relaxed text-2xl font-semibold"><span
                                class="bg-gradient-to-r from-red-600 to-indigo-600 text-transparent bg-clip-text"
                                v-text="card.title">
                            </span> </h5>

                        <div class="grid md:grid-cols-2 grid-cols-1 mt-6">
                            <div>
                                <h6 class="text-lg font-semibold">Price Range</h6>
                                <h6 class="text-2xl font-semibold mt-2"
                                    v-text="(card.currency.symbol)+card.min_price +' - '+(card.currency.symbol) + card.max_price">
                                </h6>
                                <h6 class="text-slate-400 mt-2">
                                    <b>Regions: </b><span v-for="(r,i) in card.regions" :key="i">{{ r.name + ' '
                                    }}</span>
                                </h6>
                            </div>
                        </div>

                        <div class="mt-6 grid grid-cols-2">

                            <div class="flex">
                                <span
                                    class="text-2xl inline-flex items-center px-3 text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                    {{ (card.currency.symbol) }}
                                </span>
                                <input :min="card.min_price" :max="card.max_price" type="number" id="amountBox"
                                    v-model="price" @input="getPrice()"
                                    class="rounded-none rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-2xl  border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    :placeholder="card.min_price +'-' + card.max_price">
                            </div>

                            <div class="text-center">
                                <h6 class="text-lg font-semibold ">Estimated price</h6>
                                <h6 class="text-slate-400 mt-1 uppercase" v-text="estimate + ' '+ currency"></h6>
                            </div>

                        </div>


                        <a @click="cart()"
                            class="cursor-pointer mt-5 text-white bg-indigo-600 border border-transparent focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center hover:bg-indigo-700 mr-2 mb-2"><svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-shopping-cart mr-2 -ml-1 w-7 h-4">
                                <circle cx="9" cy="21" r="1"></circle>
                                <circle cx="20" cy="21" r="1"></circle>
                                <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                            </svg>Add to cart</a>

                    </div>


                    <div class="grid grid-cols-1 mt-8" v-if="Object.keys(description).length  > 0">
                        <ul class="md:w-fit w-full flex-wrap justify-center text-center" id="myTab"
                            data-tabs-toggle="#StarterContent" role="tablist">
                            <li v-for="(d, i) in description" :key="i" role="tab" @click="tab(i)"
                                class="md:inline-block block md:w-fit w-full">
                                <button
                                    class="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
                                    data-tabs-target="#tuesday" type="button" role="tab" aria-controls="tuesday"
                                    aria-selected="false">{{ d.title }}</button>
                            </li>
                        </ul>

                        <div id="StarterContent" class="mt-6">

                            <div v-for="(d, i) in description" :key="i" class="tabs hidden" :id="'tab'+i"
                                role="tabpanel" aria-labelledby="tuesday-tab">
                                <div class="grid grid-cols-1">
                                    <p class="text-slate-400 mb-4" v-html="d.description"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end grid-->
        </div>
        <!--end container-->
    </section>
    <!--end end section-->
    <!-- End -->
</template>

<script>
import spinnerComponentVue from '@/components/spinnerComponent.vue';
export default {
    props: {
        catalogsData: Object,
    },

    components: { spinnerComponentVue },
    name: 'buy-page',

    data() {
        return {
            card: {},
            description: {},
            load: 'load',
            price: 0,
            estimate: 0,
            currency: 'btc',
            coreCart: {}
        }
    },

    methods: {

        tab: function (e) {
            var tabs = document.getElementsByClassName('tabs')
            for (var i = 0; i < tabs.length; i++) {
                document.getElementById('tab' + i).classList.add('hidden')
            }
            document.getElementById('tab' + e).classList.remove('hidden')

        },
        again: function () {
            this.load = 'load'
            this.loadCatalog();
        },
        cart: function () {

            if (this.price < 1) {
                document.getElementById("amountBox").focus();
                document.getElementById("amountBox").style.borderColor = "red";
                alert('please input an amount')
            }

            if (Object.keys(this.card).length > 0 && this.price > 0) {
                var data =
                {
                    currency: this.card.currency,

                    data: [
                        {
                            sku: this.card.sku,
                            upc: this.card.upc,
                            price: this.price,
                            title: this.card.title,
                            // estimate: this.estimate,
                            image: this.card.image
                        }
                    ]
                }
                // this.axios.get('/catalogs/this.card.sku/availability/' { headers: { 'x-api-key': process.env.VUE_APP_NOWPAYMENT } })
                //     .then((e) => {
                //         this.estimate = e.data.estimated_amount
                //         this.currency = e.data.currency_to
                //     }).catch((e) => {
                //         console.log(e);
                //     });
                if (this.$cookies.isKey('coreCart') != false) {
                    var a = this.$cookies.get('coreCart'); a.data.push(data.data[0]);
                    this.coreCart = a;
                } else {
                    this.coreCart = data;
                }

                this.$cookies.set("coreCart", this.coreCart, -1);
                this.$router.push({ name: 'checkout' });
            }
        },

        getPrice: function () {
            if (this.price > 0) {
                this.axios.get('https://api.nowpayments.io/v1/estimate?amount=' + parseFloat(this.price) + '&currency_from=' + (this.card.currency.code).toLowerCase() + '&currency_to=' + this.currency + '', { headers: { 'x-api-key': process.env.VUE_APP_NOWPAYMENT } })
                    .then((e) => {
                        this.estimate = e.data.estimated_amount
                        this.currency = e.data.currency_to
                    }).catch((e) => {
                        console.log(e);
                    });
            }
        },


        cryptoExchange: function () {
            this.axios.get('https://api.nowpayments.io/v1/status')
                .then(() => {
                    this.axios.get('https://api.nowpayments.io/v1/currencies', { headers: { 'x-api-key': process.env.VUE_APP_NOWPAYMENT } })
                        .then((e) => {
                            console.log(e);
                        }).catch((e) => {
                            console.log(e);
                        });
                }).catch((e) => {
                    console.log(e);
                });


        },

        loadCatalog: function () {
            const sku = this.$route.query.sk;
            if (this.catalogsData.info != undefined && Object.keys(JSON.parse(JSON.stringify(this.catalogsData.info))).length > 0) {
                var data = JSON.parse(JSON.stringify(this.catalogsData.info));

                for (const key in data) {
                    if (parseInt(data[key].sku) === parseInt(sku)) {
                        this.card = data[key]
                    }
                }

                console.log(this.card)

                if (Object.keys(JSON.parse(JSON.stringify(this.card))).length > 0) {
                    this.description = JSON.parse(this.card.description).content;
                }


                this.load = 'true'
            } else {
                this.load = 'false'
            }
        }
    },

    created() {
        if (this.$route.query.sk == undefined) {
            this.$router.push({ name: 'explore' });
        }
    },

    updated() {
        if (Object.keys(this.card) < 1) {
            this.loadCatalog();
        }
    },

    mounted() {
        if (this.catalogsData.info != undefined && Object.keys(this.catalogsData.info).length > 0) {
            this.loadCatalog();
        }

    }
}
</script>

<style>

</style>