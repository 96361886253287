import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)
import router from "./router";
import './index.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'


axios.defaults.baseURL = 'https://api.ezpaypin.com/';
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.common['Authorization'] = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3NfaWQiOjMzNCwiZXhwIjoxNjYyNjQzOTY0LCJwcm90ZWN0X2tleSI6IkQzMTIzNzRBOEFGNDgyMDMyNTJGNDBFNTJCNjY2OERCIiwidXNlcl9pZCI6NzcwM30.HRKYAdBqLX5xx1cjyuJyLruKGTtJ83OqdpHoorNVyCY';

app.use(router);
app.use(VueAxios, axios);
app.use(VueCookies);


router.isReady().then(() => {
    app.mount('#init');
})

